import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import exploreIcon from "assets/images/explore-button.png";
import exploreIcon2 from "assets/images/explore-button-2.png";
import iconWallet from "assets/images/icon-wallet.png";
import iconWallet2 from "assets/images/icon-wallet-2.png";
import iconSchedule from "assets/images/icon-schedule.png";
import iconSchedule2 from "assets/images/icon-schedule-2.png";
import iconSell from "assets/images/icon-sell-3.png";
import iconSell2 from "assets/images/icon-sell-2.png";
import { MdOutlineAppRegistration } from "react-icons/md";
// import iconSell from "assets/images/icon-sell.png";

interface IB extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  className?: string;
  icon?: "wallet" | "arrow" | "schedule" | "sell" | "register";
  typeButton?: 1 | 2 | 3 | 4 | 5 | 6;
  disable?: boolean;
  isLoading?: boolean;
}
const Button = ({
  text,
  className,
  icon,
  typeButton = 1,
  disable,
  isLoading,
  ...props
}: IB) => {
  const { t } = useTranslation();
  return (
    <Wrap
      className={`tb-${typeButton} ${className} ${disable ? "disable" : ""} `}
      {...props}
    >
      <div>
        {!isLoading ? (
          <>
            {icon === "schedule" && <div className="bt-icon-schedule"></div>}
            {icon === "wallet" && <div className="bt-icon-wallet"></div>}
            {icon === "register" && <MdOutlineAppRegistration size={22} />}
            <span className={`color-black size-2`}>{t(text)}</span>
            {icon === "arrow" && <div className="bt-icon-arrow"></div>}
            {icon === "sell" && <div className="bt-icon-sell"></div>}
          </>
        ) : (
          <Loading size="small" color="white" />
        )}
      </div>
    </Wrap>
  );
};
export default Button;

const Wrap = styled.button`
  width: 220px;
  padding: 2px;
  height: 50px;
  cursor: pointer;
  background: linear-gradient(
    266.31deg,
    #e1ffe9 0%,
    rgba(193, 255, 210, 0.8) 42.71%,
    rgba(193, 255, 210, 0) 100%
  );
  padding: 1px;
  border-radius: 4px;
  display: flex;
  > div {
    position: relative;
    border-radius: 4px;
    /* border: 2px solid #FFFFFF; */
    background: #59d87a;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* transition: 0.5s; */
    transition: 0.3s ease-out;
    > span {
      transition: 0.3s ease-out;
    }
    > svg {
      transition: 0.3s ease-out;
      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
    > div {
      width: 24px;
      height: 24px;
      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
      &.loading-element {
        margin-right: 0;
        margin-left: 0;
      }
      transition: 0.3s ease-out;
      /* background-image: url(${iconWallet}); */
      background-size: 100% 100%;
    }
    .bt-icon-schedule {
      background-image: url(${iconSchedule});
    }
    .bt-icon-wallet {
      background-image: url(${iconWallet});
    }
    .bt-icon-arrow {
      background-image: url(${exploreIcon});
    }
    .bt-icon-sell {
      background-image: url(${iconSell});
    }
  }
  &:hover {
    background: linear-gradient(
      266.31deg,
      #e1ffe9 0%,
      rgba(193, 255, 210, 0.8) 42.71%,
      rgba(193, 255, 210, 0) 100%
    );
    > div {
      background: #1eaa43;
      > svg {
        fill: #ffff;
      }
      > span {
        color: #ffff;
      }
      .bt-icon-schedule {
        background-image: url(${iconSchedule2});
      }
      .bt-icon-wallet {
        background-image: url(${iconWallet2});
      }
      .bt-icon-arrow {
        background-image: url(${exploreIcon2});
      }
      .bt-icon-sell {
        background-image: url(${iconSell2});
      }
    }
  }
  &.tb-2 {
    background: transparent;
    border: 1px solid #8cf5a8;
    > div {
      background: transparent;
      > span {
        color: #8cf5a8;
      }
    }
    &:hover {
      border: none;
      background: linear-gradient(
        266.31deg,
        #e1ffe9 0%,
        rgba(193, 255, 210, 0.8) 42.71%,
        rgba(193, 255, 210, 0) 100%
      );
      > div {
        background: #1eaa43;
        > span {
          color: #ffff;
        }
      }
    }
  }
  &.tb-6 {
    background: transparent;
    border: 1px solid #8cf5a8;
    > div {
      background: transparent;
      > span {
        color: #8cf5a8;
      }
    }
    &:hover {
      background: transparent;
      border: 1px solid #1eaa43;
      > div {
        background: transparent;
        > span {
          color: #1eaa43;
        }
      }
    }
  }
  &.disable,
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  ${breakpointsMedias.max1199} {
    width: 159px;
    height: 36px;
    > div {
      > img {
        width: 18px;
      }
    }
  }
  ${breakpointsMedias.max767} {

  }
`;
